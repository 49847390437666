import React, { useContext } from 'react'
import LoginContext from '../../context/authentication/LoginContext'
import logo from '../../assets/logos/logo leap.png'
import AuthenticationContext from '../../context/authentication/AuthenticationContext'

const Login = () => {
  const {email, setEmail, password, setPassword, handleSubmit} = useContext(LoginContext)
  const {setAuthenticatedUser} = useContext(AuthenticationContext)

  setAuthenticatedUser(null)
  
  return (
    <div className="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
      <div className="d-flex align-items-center justify-content-center w-100">
        <div className="row justify-content-center w-100">
          <div className="col-md-8 col-lg-6 col-xxl-3">
            <div className="card mb-0">
              <div className="card-body">
                <a href="/" className="text-nowrap logo-img text-center d-block py-3 w-100">
                  <img src={logo} width="180" alt="" />
                </a>
                {/* <p className="text-center">Your Social Campaigns</p> */}
                <form onSubmit={(e) => handleSubmit(e)}>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input type="email" className="form-control" id="email" aria-describedby="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="password" className="form-label">Mot de passe</label>
                    <input type="password" className="form-control" id="password" aria-describedby="password" value={password} onChange={(e) => setPassword(e.target.value)}  />
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <a className="text-primary fw-bold" href="/">Mot de passe oublié ?</a>
                  </div>
                  <input type='submit' className="btn btn-primary w-100 py-8 fs-4 mb-4 rounded-2" value='Se connecter' />
                  {/* <div className="d-flex align-items-center justify-content-center">
                    <p className="fs-4 mb-0 fw-bold">New to Modernize?</p>
                    <a className="text-primary fw-bold ms-2" href="./authentication-register.html">Create an account</a>
                  </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login