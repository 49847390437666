import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import PackageContext from '../../context/package/PackageContext'

const PackageHeader = () => {
    const {idGroupage} = useParams()
    const {groupage, resetFilters} = useContext(PackageContext)
  return (
    <div className='d-flex flex-column flex-md-row justify-content-between align-items-center border-bottom pb-3 gap-2'>
    <h5 className="card-title fw-semibold">Liste des paquets</h5>
    {groupage.status === 'CREATED' && <a href={`/groupages/${idGroupage}/packages/new`} className='btn btn-primary'><i className="ti ti-file-plus me-2"></i> Nouveau paquet</a>}
    <div className='d-flex gap-2'>
      <button className='btn btn-success' data-bs-toggle="modal" data-bs-target="#package_search_modal">Rechercher</button>
      <button className='btn btn-success' data-bs-toggle="modal" data-bs-target="#package_filter_modal">Filtres</button>
      <button className='btn btn-success' data-bs-toggle="modal" data-bs-target="#package_sort_modal">Trier</button>
      <button type="submit" class="btn btn-warning" onClick={() => resetFilters()}>Effacer</button>
    </div>
</div>
  )
}

export default PackageHeader