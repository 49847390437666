import React, { useContext } from 'react'
import PackageContext from '../../context/package/PackageContext'

const PackageSearchModal = () => {
    const paiementValues = [
        {
            'label' : "Payé",
            'value' : "1"
        },
        {
            'label' : "Non payé",
            'value' : "0"
        }
    ]
    const statutValues = ['RECEIVED', 'IN_TRANSIT', 'ARRIVED', 'CUSTOM_CLEARANCE', 'WITHDRAWN']
    const sortValues = ['Croissant', 'Décroissant']

    const {
        paiementFiltre, setPaiementFiltre,
        weightSort, setWeightSort, 
        priceSort, setPriceSort,
        handleStatutCheckboxChange,
        statutFiltres,
        label, setLabel,
        receiverName, setReceiverName, 
        receiverPhone, setReceiverPhone
    } = useContext(PackageContext)
  return (
    <>
        <div class="modal fade" id="package_filter_modal" tabindex="-1" role="dialog" aria-labelledby="package_filter_modal_title" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="package_filter_modal_title">Filtrez les packages</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <h6>Par paiement</h6>
                        {
                            paiementValues.map((value) =>
                                <div className="mb-1 form-check">
                                    <input type="radio" class="form-check-input" checked={paiementFiltre === value.value} onChange={(e) => setPaiementFiltre(e.target.value)} value={value.value} id={value.label}/>
                                    <label class="form-check-label" htmlFor={value.label}>{value.label}</label>
                                </div>
                            )
                        }

                        <h6>Par statut</h6>
                        {
                            statutValues.map((value) =>
                                <div className="mb-1 form-check">
                                    <input type="checkbox" class="form-check-input" checked={statutFiltres.includes(value)} onChange={() => handleStatutCheckboxChange(value)} value={value} id={value}/>
                                    <label class="form-check-label" htmlFor={value}>{value}</label>
                                </div>
                            )
                        }
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Fermer</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="package_sort_modal" tabindex="-1" role="dialog" aria-labelledby="package_sort_modal_title" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="package_sort_modal_title">Triez les packages</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <h6>Par poids</h6>
                        {
                            sortValues.map((value) =>
                                <div className="mb-1 form-check">
                                    <input type="radio" class="form-check-input" checked={weightSort === value} onChange={(e) => setWeightSort(e.target.value)} value={value} id={`${value}-poids`}/>
                                    <label class="form-check-label" htmlFor={`${value}-poids`}>{value}</label>
                                </div>
                            )
                        }

                        <h6>Par Prix</h6>
                        {
                            sortValues.map((value) =>
                                <div className="mb-1 form-check">
                                    <input type="radio" class="form-check-input" checked={priceSort === value} onChange={(e) => setPriceSort(e.target.value)} value={value} id={`${value}-prix`}/>
                                    <label class="form-check-label" htmlFor={`${value}-prix`}>{value}</label>
                                </div>
                            )
                        }
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Fermer</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="package_search_modal" tabindex="-1" role="dialog" aria-labelledby="package_search_modal_title" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <form class="modal-content" onSubmit={(e) => e.preventDefault()}>
                    <div class="modal-header">
                        <h5 class="modal-title" id="package_search_modal_title">Recherchez des packages</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div className="mb-3">
                            <label htmlFor="" className='form-label'>Par label</label>
                            <input class="form-control mr-sm-2" type="search" placeholder="Label" aria-label="Label" value={label} onChange={(e) => setLabel(e.target.value)} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="" className='form-label'>Par nom du destinataire</label>
                            <input class="form-control mr-sm-2" type="search" placeholder="Nom du destinataire" aria-label="receiverName" value={receiverName} onChange={(e) => setReceiverName(e.target.value)} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="" className='form-label'>Par contact du destinataire</label>
                            <input class="form-control mr-sm-2" type="search" placeholder="Contact du destinataire" aria-label="receiverPhone" value={receiverPhone} onChange={(e) => setReceiverPhone(e.target.value)} />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Fermer</button>
                    </div>
                </form>
            </div>
        </div>
    </>
  )
}

export default PackageSearchModal