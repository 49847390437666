import axios from "axios"


const BASE_URL = process.env.REACT_APP_BASE_URL;
const token = localStorage.getItem("authenticationToken")


export default axios.create({
})

const apiClient = axios.create({
    baseURL: BASE_URL,
    headers: {
        ...(token && { Authorization: "Bearer " + token }),
        'Content-Type': 'application/json',
    }
})


// Define common API methods
const apiClientGet = (url, params = {}, config = {}) => {
    const res = apiClient.get(url, params)
        .catch((err) => {
            console.log(err.response)
            if (err.response.data === "Missing authorization header") {
                localStorage.removeItem('authenticationToken')
                localStorage.removeItem('authenticatedUser')
                window.location.reload()
            }
            if (err.response.status === 401) {
                localStorage.removeItem('authenticationToken')
                localStorage.removeItem('authenticatedUser')
                window.location.reload()
            }
            throw err
        })
    return res
};


const apiClientDelete = (url, config = {}) => {
    return apiClient.delete(url, config);
};

const apiClientPut = (url, data = {}, config = {}) => {
    const res = apiClient.put(url, data, config).catch((err) => {
        console.log(err.response)
        if (err.response.data === "Missing authorization header") {
            localStorage.removeItem('authenticationToken')
            localStorage.removeItem('authenticatedUser')
            window.location.reload()
        }
        if (err.response.status === 401) {
            localStorage.removeItem('authenticationToken')
            localStorage.removeItem('authenticatedUser')
            window.location.reload()
        }
    });
    return res
};

const apiClientPost = (url, data = {}, config = {}) => {
    const res = apiClient.post(url, data, config).catch((err) => {
        console.log(err.response)
        if (err.response.data === "Missing authorization header") {
            localStorage.removeItem('authenticationToken')
            localStorage.removeItem('authenticatedUser')
            window.location.reload()
        }
        if (err.response.status === 401) {
            localStorage.removeItem('authenticationToken')
            localStorage.removeItem('authenticatedUser')
            window.location.reload()
        }
        throw err
    })
    return res
};

// Export API methods
export { apiClientGet, apiClientDelete, apiClientPost, apiClientPut };