import React, { useContext } from 'react'
import PackageEditContext from '../../context/package/PackageEditContext'

const PackageEdit = () => {
    const {
        handleEditPackage,
        id,
        label, setLabel,
        weight, setWeight,
        discount, setDiscount,
        senderName, setSenderName,
        senderPhone, setSenderPhone,
        receiverName, setReceiverName,
        receiverPhone, setReceiverPhone,
        paymentStatus, setPaymentStatus,
        handleFilesLoad, fileError,
        files
    } = useContext(PackageEditContext)

    return (
        <div className="card">
            <div className="card-body">
                <h5 className="card-title fw-semibold mb-4">Modification du paquet</h5>
                <div className="card">
                    <div className="card-body">
                        <form className="row" onSubmit={(e) => handleEditPackage(e)}>
                            <div className="mb-3">
                                <label htmlFor="label" className="form-label">Label</label>
                                <input type="text" className="form-control" id="label" value={label} onChange={(e) => setLabel(e.target.value)} aria-describedby="label" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="weight" className="form-label">Poids</label>
                                <input type="number" className="form-control" id="weight" value={weight} min={1} step={0.1} onChange={(e) => setWeight(e.target.value)} aria-describedby="weight" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="discount" className="form-label">Reduction</label>
                                <input type="number" className="form-control" id="discount" value={discount} min={0} onChange={(e) => setDiscount(e.target.value)} aria-describedby="discount" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="senderName" className="form-label">Nom de l'expéditeur</label>
                                <input type="text" className="form-control" id="senderName" value={senderName} onChange={(e) => setSenderName(e.target.value)} aria-describedby="senderName" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="senderPhone" className="form-label">Contact de l'expéditeur</label>
                                <input type="text" className="form-control" id="senderPhone" value={senderPhone} onChange={(e) => setSenderPhone(e.target.value)} aria-describedby="senderPhone" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="receiverName" className="form-label">Nom du destinataire</label>
                                <input type="text" className="form-control" id="receiverName" value={receiverName} onChange={(e) => setReceiverName(e.target.value)} aria-describedby="receiverName" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="receiverPhone" className="form-label">Contact du destinataire</label>
                                <input type="text" className="form-control" id="receiverPhone" value={receiverPhone} onChange={(e) => setReceiverPhone(e.target.value)} aria-describedby="receiverPhone" />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="paymentStatus" className='form-label'>Statut de paiement</label>
                                <select name="paymentStatus" id="paymentStatus" className='form-select' value={paymentStatus} onChange={(e) => setPaymentStatus(e.target.value)}>
                                    <option value={true}>Payé</option>
                                    <option value={false}>Non payé</option>
                                </select>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="image" className="form-label">Image de couverture</label>
                                <input type="file" multiple={true} accept='image/*' className="form-control" id="image" aria-describedby="image" onChange={(e) => handleFilesLoad(e)} />
                                {fileError && <small className='text-danger'>Vous pouvez ajouter au maximum 04 images</small>}
                            </div>

                            {
                                files.length > 0 &&
                                <div className="mb-3 row">
                                    {
                                        files.map((file) => <div className='col-md-3'><img className='img-fluid' src={URL.createObjectURL(file)} alt='' /></div>)
                                    }
                                </div>
                            }

                            <div className="d-flex gap-3">
                                <a href={`/packages/${id}/infos`} type="button" className="btn btn-danger">Annuler</a>
                                <button type="submit" className="btn btn-primary" id='edit_package_button'>Modifier</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PackageEdit