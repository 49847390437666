import { createContext, useContext, useEffect, useState } from "react";
import AuthenticationContext from "../authentication/AuthenticationContext";
import { useParams } from "react-router-dom";
import api, { apiClientGet } from "../../api/api";
import { Bounce, toast } from "react-toastify";

const PackageContext = createContext({})

export const PackageProvider = ({ children }) => {
    const { authenticationToken } = useContext(AuthenticationContext)
    const { idGroupage } = useParams()

    const [packages, setPackages] = useState([])
    const [groupage, setGroupage] = useState({})

    const [statutFiltres, setStatutFiltres] = useState([])
    const [paiementFiltre, setPaiementFiltre] = useState(null)
    const [weightSort , setWeightSort] = useState()
    const [priceSort, setPriceSort] = useState()
    const [label, setLabel] = useState('')
    const [receiverName, setReceiverName] = useState('')
    const [receiverPhone, setReceiverPhone] = useState('')

    useEffect(() => {
        apiClientGet(`admin/groupage/parcel/list?groupId=${idGroupage}`).then((res) => {
            setPackages(res.data.content)
        }).catch((err) => {
            toast.error("Echec de la recupération de la liste des paquets", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
        })
    }, [authenticationToken, idGroupage])

    useEffect(() => {
        apiClientGet(`admin/groupage/group?id=${idGroupage}`).then((res) => {
            setGroupage(res.data)
        }).catch((err) => {
            toast.error("Une erreur est survenue lors de la recupération du groupage. Veuillez reéssayer !!!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
        })

    }, [authenticationToken, idGroupage])

    const handleStatutCheckboxChange = (critere) => {
        setStatutFiltres((prevFiltres) =>
          prevFiltres.includes(critere)
            ? prevFiltres.filter((item) => item !== critere)
            : [...prevFiltres, critere]
        );
      };

      const resetFilters = () => {
        setStatutFiltres([])
        setPriceSort(undefined)
        setWeightSort(undefined)
        setPaiementFiltre(undefined)
        setLabel('')
        setReceiverName('')
        setReceiverPhone('')
    } 

    return (
        <PackageContext.Provider
            value={
                {
                    packages,
                    groupage,
                    statutFiltres,
                    paiementFiltre, setPaiementFiltre,
                    weightSort, setWeightSort, 
                    priceSort, setPriceSort,
                    label, setLabel,
                    receiverName, setReceiverName,
                    receiverPhone, setReceiverPhone,
                    resetFilters,
                    handleStatutCheckboxChange
                }
            }
        >
            {children}
        </PackageContext.Provider>
    )
}

export default PackageContext