import React from 'react'

const ServiceHeader = () => {
  return (
    <div className='d-flex flex-column flex-sm-row justify-content-between align-items-center'>
        <h5 className="card-title fw-semibold">Liste des services</h5>
        <a href='/services/new' className='btn btn-primary'><i className="ti ti-file-plus me-2"></i> Nouveau</a>
    </div>
  )
}

export default ServiceHeader