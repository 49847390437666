import { createContext, useContext, useEffect, useState } from "react";
import AuthenticationContext from "../authentication/AuthenticationContext";
import { useParams } from "react-router-dom";
import api, { apiClientGet, apiClientPut } from "../../api/api";
import { Bounce, toast } from "react-toastify";

const PackageInfosContext = createContext({})

export const PackageInfosProvider = ({ children }) => {
    const { authenticationToken } = useContext(AuthenticationContext)
    const { idPackage } = useParams()
    const [paquet, setPaquet] = useState({})
    const [image, setImage] = useState()
    const [files, setFiles] = useState([])
    const [fileError, setFileError] = useState(false)

    useEffect(() => {
        apiClientGet(`/admin/groupage/parcel?id=${idPackage}`).then((res) => {
            setPaquet(res.data)
            console.log(res.data)
        }).catch((err) => {
            toast.error("Echec de la recupération des infos du paquet", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false, 
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
        })
    }, [authenticationToken, idPackage])

    const handleMarquerCommeRecu = () => {
        apiClientGet(`/admin/groupage/parcel/withdraw?id=${idPackage}`).then((res) => {
            toast.success("Opération réussie", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false, 
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
        }).catch((err) => {
            toast.error("Une erreur est survenue", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false, 
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
            console.log(err)
        })
    }

    const handleDeleteImage = () => {
        apiClientPut(`/admin/groupage/parcel/?id=${idPackage}&imageName=${image.file}`).then((res) => {
            toast.success("Opération réussie", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false, 
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
        }).catch((err) => {
            toast.error("Une erreur est survenue", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false, 
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
            console.log(err)
        })
    }

    const handleAddImage = (e) => {
        e.preventDefault()

        const imageNames = files.map((file) => file.name).join(',')

        apiClientPut(`/admin/groupage/parcel/image?id=${idPackage}&imageNames=${imageNames}`).then((res) => {
            for (let i = 0; i < files.length; i++){
                console.log(res.data)
                api.put(res.data[i].uploadUrl, files[i], {
                    headers : {
                        'Content-Type' : files[i].type
                    }
                }).then((res) => {
                    console.log('ok')
                }).catch((err) => {
                    console.log(err)
                })
            }
            toast.success("Images ajoutées avec succès", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })

            window.location.reload()
        }).catch((err) => {
            console.log(err)
            toast.error("Une erreur est survenue lors de l'ajout des images. Veuillez reéssayer !!!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
        })

    }

    const handleFilesLoad = (e) => {
        if ([...e.target.files].length > 4){
            setFileError(true)
            document.getElementById("add_package_image_button").classList.add('disabled')
        } else{
            setFiles([...e.target.files])
            setFileError(false)
            document.getElementById("add_package_image_button").classList.remove('disabled')
        }
    }
 
    return (
        <PackageInfosContext.Provider
            value={
                {
                    paquet,
                    handleMarquerCommeRecu,
                    image, setImage,
                    handleDeleteImage,
                    handleFilesLoad, fileError,
                    files,
                    handleAddImage
                }
            }
        >
            {children}
        </PackageInfosContext.Provider>
    )
}

export default PackageInfosContext