import React, { useContext } from 'react'
import AccountContext from '../../context/account/AccountContext'
import profile from '../../assets/profile/user-1.jpg'

const AccountDetails = () => {
    const {
        account,
        handleChangePassword,
        oldPassword, setOldPassword,
        newPassword, setNewPassword,
        confirmPassword, setConfirmPassword,
        avatar, setAvatar,
        handleChangeAvatar
    } = useContext(AccountContext)

    const formatDate = (date) => {
        if (!date) return '---';
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const year = d.getFullYear();
        return `${day}/${month}/${year}`;
    }

    return (
        <div className="card">
            <div className="card-body">
                <div className='d-flex justify-content-between align-items-center p-3'>
                    <h5 className="card-title fw-semibold mb-4">Informations du profil</h5>
                </div>

                <div className="card">
                    <div className="card-body">
                        <div className='row mb-3 pb-3 border-bottom'>
                            <div className="col-12 d-flex justify-content-center">
                                <img src={account && account.avatar ? account.avatar : profile} alt="" width="150" height="150" className="rounded-circle" />
                            </div>
                        </div>
                        <form className="row">
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="label" className="form-label">Nom</label>
                                <input type="text" className="form-control" id="label" aria-describedby="label" value={account && account.lastName ? account.lastName : '---'} disabled />
                            </div>
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="weight" className="form-label">Prénom</label>
                                <input type="text" className="form-control" id="weight" aria-describedby="weight" value={account && account.firstName ? account.firstName : '---'} disabled />
                            </div>
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="price" className="form-label">Email</label>
                                <input type="text" className="form-control" id="price" aria-describedby="price" value={account && account.email ? account.email : '---'} disabled />
                            </div>
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="payment" className="form-label">Contact</label>
                                <input type="text" className="form-control" id="payment" aria-describedby="payment" value={account && account.phoneNumber ? account.phoneNumber : '---'} disabled />
                            </div>
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="senderName" className="form-label">Date de naissance</label>
                                <input type="text" className="form-control" id="senderName" aria-describedby="senderName" value={account && account.birthday ? formatDate(account.birthday) : '---'} disabled />
                            </div>
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="gender" className="form-label">Genre</label>
                                <input type="text" className="form-control" id="gender" aria-describedby="gender" value={account && account.gender && account.gender === 'MALE' ? "Masculin" : 'Feminin'} disabled />
                            </div>
                        </form>
                    </div>

                    <div className="d-flex gap-3 p-3 border-top">
                        <button type="button" className="btn btn-danger" onClick={() => window.history.back()}>Retour</button>
                        <a href={`/profil/update`} type="button" className="btn btn-secondary">Modifier</a>
                        <button type="button" className="btn btn-secondary" data-bs-toggle='modal' data-bs-target='#password'>Mot de passe</button>
                        <button type="button" className="btn btn-secondary" data-bs-toggle='modal' data-bs-target='#avatar'>Avatar</button>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="password" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="password_label" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <form class="modal-content" onSubmit={(e) => handleChangePassword(e)}>
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="password_label">Changer de mot de passe</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className="mb-3">
                                <label htmlFor="image" className="form-label">Mot de passe actuel</label>
                                <input type="password" className="form-control" id="image" aria-describedby="image" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="image" className="form-label">Nouveau mot de passe</label>
                                <input type="password" className="form-control" id="image" aria-describedby="image" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="image" className="form-label">Confirmez le mot de passe</label>
                                <input type="password" className="form-control" id="image" aria-describedby="image" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-success" id='add_package_image_button' data-bs-dismiss="modal">Modifier</button>
                        </div>
                    </form>
                </div>
            </div>

            <div class="modal fade" id="avatar" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="avatar_label" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <form class="modal-content" onSubmit={(e) => handleChangeAvatar(e)}>
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="avatar_label">Modifier l'avatar</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className="mb-3">
                                <label htmlFor="image" className="form-label">Avatar</label>
                                <input type="file" accept='image/*' className="form-control" id="image" aria-describedby="image" onChange={(e) => setAvatar(e.target.files[0])} />
                            </div>

                            {
                                avatar &&
                                <div className="mb-3 row">
                                    <div className='col-md-12'><img width={'100%'} height={'250px'} src={URL.createObjectURL(avatar)} alt='' /></div>
                                </div>
                            }
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-success" id='add_package_image_button' data-bs-dismiss="modal">Modifier</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AccountDetails