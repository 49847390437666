import React, { useContext } from 'react'
import PackageInfosContext from '../../context/package/PackageInfosContext'
import QRCode from "react-qr-code"

const PackageInfos = () => {
    const { paquet, handleMarquerCommeRecu, image, setImage, handleDeleteImage, handleFilesLoad, fileError, files, handleAddImage } = useContext(PackageInfosContext)
    return (
        <div className="card">
            <div className="card-body">
                <div className='d-flex flex-column flex-lg-row justify-content-between align-items-center p-3'>
                    <h5 className="card-title fw-semibold">Informations du paquet</h5>
                    <div className='d-flex gap-2'>
                        <button type="button" className="btn btn-primary" data-bs-toggle='modal' data-bs-target='#qr_code'><QRCode value={window.location.href} style={{ width: '25px', height: '25px' }} /></button>
                        {paquet.status === "ARRIVED" && <button type="button" className="btn btn-primary" data-bs-toggle='modal' data-bs-target='#withdraw'>Withdraw</button>}
                    </div>
                </div>

                <div className="card">
                    <div className="card-body">
                        <form className="row">
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="label" className="form-label">Label</label>
                                <input type="text" className="form-control" id="label" value={paquet.label} aria-describedby="label" disabled />
                            </div>
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="weight" className="form-label">Poids</label>
                                <input type="text" className="form-control" id="weight" value={paquet.weight} aria-describedby="weight" disabled />
                            </div>
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="price" className="form-label">Prix</label>
                                <input type="text" className="form-control" id="price" value={paquet.price} aria-describedby="price" disabled />
                            </div>
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="payment" className="form-label">Statut</label>
                                <input type="text" className="form-control" id="payment" value={paquet.paymentStatus ? 'Payé' : 'Non payé'} aria-describedby="payment" disabled />
                            </div>
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="senderName" className="form-label">Nom de l'expéditeur</label>
                                <input type="text" className="form-control" id="senderName" value={paquet.senderName} aria-describedby="senderName" disabled />
                            </div>
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="senderPhone" className="form-label">Contact de l'expéditeur</label>
                                <input type="text" className="form-control" id="senderPhone" value={paquet.senderPhone} aria-describedby="senderPhone" disabled />
                            </div>
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="receiverName" className="form-label">Nom du destinataire</label>
                                <input type="text" className="form-control" id="receiverName" value={paquet.receiverName} aria-describedby="receiverName" disabled />
                            </div>
                            <div className="mb-3 col-lg-6">
                                <label htmlFor="receiverPhone" className="form-label">Contact du destinataire</label>
                                <input type="text" className="form-control" id="receiverPhone" value={paquet.receiverPhone} aria-describedby="receiverPhone" disabled />
                            </div>
                            <div className="mb-3  col-lg-6">
                                <label htmlFor="depart" className="form-label">Date d'arrivée</label>
                                {paquet.expectedArrivalDay && <input type="text" className="form-control" id="depart" value={paquet.expectedArrivalDay.slice(0, 10)} aria-describedby="adresse" disabled />}
                            </div>
                        </form>
                    </div>

                    <div className="card-body p-4 row">
                        <div className="col-lg-8">
                            <div className="mb-4">
                                <h5 className="card-title fw-semibold">Historique</h5>
                            </div>
                            <ul className="timeline-widget mb-0 position-relative mb-n5">
                                {
                                    paquet.statusList && paquet.statusList.map(
                                        (statut) =>
                                            <li key={statut.status} className="timeline-item d-flex justify-content-evenly position-relative overflow-hidden">
                                                <div className="timeline-time text-dark flex-shrink-0 text-end">{statut.createdAt.slice(0, 10)}</div>
                                                <div className="timeline-badge-wrap d-flex flex-column align-items-center">
                                                    <span className="timeline-badge border-2 border border-success flex-shrink-0 my-8"></span>
                                                </div>
                                                <div className="timeline-desc fs-3 text-dark mt-n1">{statut.status}</div>
                                            </li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>

                    <div className="d-flex gap-3 p-3 mt-5 border-top">
                        <a href={`/groupages/${paquet.groupId}/packages`} type="button" className="btn btn-danger">Retour</a>
                        {paquet.status !== "WITHDRAWN" && <a href={`/packages/${paquet.id}/edit`} type="button" className="btn btn-secondary">Modifier</a>}
                        {paquet.status !== "WITHDRAWN" && <button type="button" data-bs-toggle='modal' data-bs-target='#add_package_image' className="btn btn-secondary">Images</button>}
                    </div>

                    <div className="card-body p-4">
                        <div>
                            <div className="mb-4">
                                <h5 className="card-title fw-semibold">Images</h5>
                            </div>
                            <div className="row">
                                {
                                    paquet.images && paquet.images.length > 0 && paquet.images.map((image) =>
                                        <div className="col-lg-3">
                                            <img data-bs-toggle="modal" data-bs-target='#package_image' src={image.url} height={'200px'} width={'100%'} onClick={() => setImage(image)} alt="..." />
                                        </div>
                                    ) 
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="qr_code" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="qr_code_label" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="qr_code_Label">QR Code</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body d-flex justify-content-center">
                            <QRCode
                                value={window.location.href}
                            />
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="withdraw" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="withdraw_label" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="withdraw_label">Marquer le coli comme reçu</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body d-flex justify-content-center">
                            Etes vous sûr de vouloir marquer ce colis comme reçu ?
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={() => handleMarquerCommeRecu()}>Oui</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="package_image" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="package_image_label" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="package_image_label">Image</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body d-flex justify-content-center">
                            {
                                image && <img data-bs-toggle="modal" data-bs-target='#package_image' src={image.url} height={'300px'} width={'100%'} alt="..." />
                            }
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-bs-dismiss="modal" onClick={() => handleDeleteImage()}>Supprimer</button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="add_package_image" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="add_package_image_label" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <form class="modal-content" onSubmit={(e) => handleAddImage(e)}>
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="add_package_image_label">Ajouter des images</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className="mb-3">
                                <label htmlFor="image" className="form-label">Image de couverture</label>
                                <input type="file" multiple={true} accept='image/*' className="form-control" id="image" aria-describedby="image" onChange={(e) => handleFilesLoad(e)} />
                                {fileError && <small className='text-danger'>Vous pouvez ajouter au maximum 04 images</small>}
                            </div>

                            {
                                files.length > 0 &&
                                <div className="mb-3 row">
                                    {
                                        files.map((file) => <div className='col-md-6'><img width={'100%'} height={'100px'} src={URL.createObjectURL(file)} alt='' /></div>)
                                    }
                                </div>
                            }
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-success" id='add_package_image_button' data-bs-dismiss="modal">Ajouter</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default PackageInfos