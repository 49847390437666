import { createContext, useContext, useEffect, useState } from "react";
import AuthenticationContext from "../authentication/AuthenticationContext";
import { apiClientGet, apiClientPut } from "../../api/api";
import { useNavigate } from "react-router-dom";
import { Bounce, toast } from "react-toastify";

const AccountUpdateContext = createContext({})

export const AccountUpdateProvider = ({children}) => {
    const {authenticationToken} = useContext(AuthenticationContext)

    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [email, setEmail] = useState()
    const [phoneNumber, setPhoneNumber] = useState()
    const [birthday, setBirthday] = useState()
    const [gender, setGender] = useState()

    const navigate = useNavigate()

    useEffect(() => {
        apiClientGet('admin/users').then((res) => {
            setLastName(res.data.lastName)
            setFirstName(res.data.firstName)
            setEmail(res.data.email)
            setPhoneNumber(res.data.phoneNumber)
            setBirthday(res.data.birthday)
            setGender(res.data.gender)
        }).catch((err) => {
            console.log(err)
        })
    }, [authenticationToken])

    const handleUpdateProfil = (e) => {
        e.preventDefault()

        const data = {
            lastName, firstName, email, phoneNumber, birthday, gender
        }

        apiClientPut('/admin/users', data).then((res) => {
            toast.success("Profil modifié avec succès", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
            navigate('/profil')
        }).catch((err) => {
            toast.error("Echec de la modification du profil", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
            console.log(err)
        })
    }

    return (
        <AccountUpdateContext.Provider
            value={
                {
                    firstName, setFirstName,
                    lastName, setLastName,
                    email, setEmail,
                    phoneNumber, setPhoneNumber,
                    birthday, setBirthday,
                    gender, setGender,
                    handleUpdateProfil
                }
            }
        >
            {children}
        </AccountUpdateContext.Provider>
    )
}


export default AccountUpdateContext