import React, { useContext } from 'react'
import profile from '../../assets/profile/user-1.jpg'
import AuthenticationContext from '../../context/authentication/AuthenticationContext'

const Header = () => {
  const { authenticatedUser, handleLogout } = useContext(AuthenticationContext)

  const toggleSidebar = () => {
    const sidebar = document.getElementById('main-wrapper');
    sidebar.classList.toggle('show-sidebar');
    sidebar.addEventListener('click', () => {
      sidebar.classList.remove('show-sidebar');
    });
  }

  return (
    <header className="app-header border-bottom">
      <nav className="navbar navbar-expand-lg navbar-light">
        <ul className="navbar-nav">
          <li className="nav-item d-block d-xl-none">
            <span className="btn btn-link nav-link" onClick={toggleSidebar}>
              <i className="ti ti-menu-2"></i>
            </span>
          </li>
        </ul>
        <div className="navbar-collapse justify-content-end px-0" id="navbarNav">
          <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-end">
            <li className="nav-item dropdown">
              <a className="nav-link nav-icon-hover" href="/" id="drop2" data-bs-toggle="dropdown"
                aria-expanded="false">
                <img src={profile} alt="" width="35" height="35" className="rounded-circle" />
              </a>
              <div className="dropdown-menu dropdown-menu-end dropdown-menu-animate-up" aria-labelledby="drop2">
                <div className="message-body text-center">
                  <a href="/#" className="d-flex align-items-center gap-2 dropdown-item">
                    <i className="ti ti-user fs-6"></i>
                    <p className="mb-0 fs-3">{`${authenticatedUser.email}`}</p>
                  </a>
                  <a href="/profil" className="d-flex align-items-center gap-2 dropdown-item">
                    <i className="ti ti-mail fs-6"></i>
                    <p className="mb-0 fs-3">Mon compte</p>
                  </a>
                  <button className="btn btn-outline-primary mx-3 mt-2 d-block" onClick={() => handleLogout()}>Se deconnecter</button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  )
}

export default Header