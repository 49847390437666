import { createContext, useContext, useEffect, useState } from "react";
import AuthenticationContext from "../authentication/AuthenticationContext";
import { apiClientGet, apiClientPost } from "../../api/api";
import { Bounce, toast } from "react-toastify";

const AccountContext = createContext({})

export const AccountProvider = ({ children }) => {
    const { authenticationToken, handleLogout } = useContext(AuthenticationContext)

    const [account, setAccount] = useState()

    const [oldPassword, setOldPassword] = useState()
    const [newPassword, setNewPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState()
    const [avatar, setAvatar] = useState()

    const handleChangePassword = (e) => {
        e.preventDefault()

        if (newPassword !== confirmPassword){
            toast.error("Les deux mots de passe doivent être identiques", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
        }
        else{
            const data = {
                oldPassword, newPassword, confirmPassword
            }
            apiClientPost('/admin/users/update-password', data).then((res) => {
                toast.success("Mot de passe modifié avec succès", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                })
                handleLogout()
            }).catch((err) => {
                toast.error("Echec de la modification du mot de passe", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                })
                console.log(err)
            })
        }
    }

    const handleChangeAvatar = (e) => {
        e.preventDefault()

        const data = {
            'avatar' : avatar.name
        }

        apiClientPost('/admin/users/update-avatar', data).then((res) => {
            console.table(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        apiClientGet('admin/users').then((res) => {
            console.log(res.data)
            setAccount(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }, [authenticationToken])

    return (
        <AccountContext.Provider
            value={
                {
                    account,
                    handleChangePassword,
                    oldPassword, setOldPassword,
                    newPassword, setNewPassword,
                    confirmPassword, setConfirmPassword,
                    avatar, setAvatar,
                    handleChangeAvatar
                }
            }
        >
            {children}
        </AccountContext.Provider>
    )
}

export default AccountContext