import React, { useContext } from 'react'
import AccountUpdateContext from '../../context/account/AccountUpdateContext'

const AccountUpdate = () => {
    const {
        firstName, setFirstName,
        lastName, setLastName,
        email, setEmail,
        phoneNumber, setPhoneNumber,
        birthday, setBirthday,
        gender, setGender,
        handleUpdateProfil
    } = useContext(AccountUpdateContext)

    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 15);

    return (
        <div className="card">
            <div className="card-body">
                <h5 className="card-title fw-semibold mb-4">Modification de profil</h5>
                <div className="card">
                    <div className="card-body">
                        <form className="row" onSubmit={(e) => handleUpdateProfil(e)}>
                            <div className="mb-3">
                                <label htmlFor="label" className="form-label">Nom</label>
                                <input type="text" className="form-control" id="label" value={lastName} onChange={(e) => setLastName(e.target.value)} aria-describedby="label" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="weight" className="form-label">Prénom</label>
                                <input type="text" className="form-control" id="weight" value={firstName} onChange={(e) => setFirstName(e.target.value)} aria-describedby="weight" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="senderName" className="form-label">Email</label>
                                <input type="text" className="form-control" id="senderName" value={email} onChange={(e) => setEmail(e.target.value)} aria-describedby="senderName" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="senderPhone" className="form-label">Contact</label>
                                <input type="text" className="form-control" id="senderPhone" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} aria-describedby="senderPhone" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="receiverName" className="form-label">Date de naissance</label>
                                <input type="date" className="form-control" id="receiverName" value={birthday ? new Date(birthday).toISOString().split("T")[0] : ''} onChange={(e) => setBirthday(e.target.value)} aria-describedby="receiverName" max={minDate.toISOString().split("T")[0]} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="paymentStatus" className='form-label'>Genre</label>
                                <select name="paymentStatus" id="paymentStatus" className='form-select' value={gender} onChange={(e) => setGender(e.target.value)}>
                                    <option>---</option>
                                    <option value={'MALE'}>Masculin</option>
                                    <option value={'FEMALE'}>Féminin</option>
                                </select>
                            </div>
                            <div className="d-flex gap-3">
                                <a href={`/profil`} type="button" className="btn btn-danger">Annuler</a>
                                <button type="submit" className="btn btn-primary">Modifier</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountUpdate